import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.text.secondary 
    }
}), { name: 'MuiFormControlLabel'});

export default function LoginForm(props) {
    const classes = useStyles();
    
    const [register, setRegister] = useState(true);
    const [login, setLogin] = useState({ email: '', password: '', eguma: ''})

    function handleRegister()
    {
        setRegister(pervState => !pervState);
        setLogin({ email: '', password: '', eguma: ''})
    }


    const handleLogin = async function()
    {
        const loginState = await props.handleLogin(login.email, login.password, login.eguma);
        if(loginState)
        {
          setLogin({ email: '', password: '', eguma: ''});
        }

    }

    const registerForm = (<React.Fragment>
      <TextField
        autoFocus
        required
        margin="dense"
        id="eguma"
        label="Ticket Nummer"
        type="text"
        value={login.eguma}
        onChange={e => setLogin({...login,  eguma: e.target.value})}
        fullWidth
      />
      <TextField
        required
        margin="dense"
        id="email"
        label="E-mail Addresse"
        type="email"
        value={login.email}
        onChange={e => setLogin({...login,  email: e.target.value})}
        fullWidth
      />
      <TextField
        required
        margin="dense"
        id="passwort"
        label="Passwort"
        type="password"
        value={login.password}
        onChange={e => setLogin({...login,  password: e.target.value})}
        fullWidth
      /></React.Fragment>);

    const loginForm = (<React.Fragment>
        <TextField
            required
          autoFocus
          margin="dense"
          id="email"
          label="E-mail Addresse"
          type="email"
          value={login.email}
          onChange={e => setLogin({...login,  email: e.target.value})}
          fullWidth
        />
        <TextField
            required
          margin="dense"
          id="passwort"
          label="Passwort"
          type="password"
          value={login.password}
          onChange={e => setLogin({...login,  password: e.target.value})}
          fullWidth
        /></React.Fragment>);

  return (
    <div>
      <Dialog open={props.showLoginForm} onClose={props.handleLoginForm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{register ? "Anmelden" : "Registrieren"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Um Kursreservationen tätigen zu können ist ein Benutzerkonto erforderlich. Mit dem Umschalter können Sie zwischen "Anmelden" für bestehende Benutzer und "Registrieren" um ein neues Benutzerkonto zu erstellen umschalten.
                </DialogContentText>
                <Typography id="errorMessage" color="error">{props.login.message}</Typography>
                {register ? loginForm : registerForm }
                <FormControlLabel
                    control={
                        <Switch
                            checked={register}
                            onChange={handleRegister}
                            name="registerSwitch"
                            color="primary"
                        />
                    }
                    label={register ? 'Anmelden' : 'Registrieren'}
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleLoginForm} color="primary" variant="contained">
                Abbrechen
            </Button>
            <Button onClick={e => handleLogin(e)} color="secondary" variant="contained">
                {register ? "Anmelden" : "Registrieren"}
            </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}
