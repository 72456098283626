import React, { useState, useEffect } from 'react';
import Calendar from './Calendar';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    height: '100vh',
    maxHeight: '100vh',
    '& .MuiBox-root': {
      padding: '0px'
  }
  },
}));

function DaySelector(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [days, setDays] = useState([]);
  const [apiresponse, setapiresponse] = useState([])
  const [rollIn, setRollIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleIn = () => {
    setRollIn((prev) => !prev);
  };

  useEffect(() => {
    fetchData().then(function(res) {
      setapiresponse(res)
    }).catch(console.log);
    
  }, []);

  useEffect(() => {
    if(apiresponse !== void(0))
    {  
      if(apiresponse.length !== 0)
      {
        setDays(selectDays())
        setIsLoaded(true);
      }
    }
  }, [apiresponse]);

  useEffect(() => {
    handleIn();
  }, []);

  function selectDays() {
    let output = [];
    let isUnique = true;
     
    apiresponse.courses.map(day => {
      isUnique = true
      if(output.length <= 0)
      {
        output.push((day.start).substr(0, 10));
      }
      for(let i = 0; i < output.length; i++) {
        if(output[i] === (day.start).substr(0, 10)) {
          isUnique = false;
        }
      }
        if(isUnique) {
          output.push((day.start).substr(0, 10));
        }

      });
      return output;
  }
    
  const fetchData = async function()
  {
      //let fetchedData = fetch('https://ymw-backend.herokuapp.com/seq/course').then(res => res.json()).catch(console.log);
      let fetchedData = fetch('https://backend.yogameetsweggis.ch/seq/course').then(res => res.json()).catch(err => console.log(err));

      return(fetchedData);
  };

  let i = -1;
  const content = () => {
  
  return(<React.Fragment>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              {days.map(day => {
                let options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
                let date = new Date(day).toLocaleDateString('de-CH', options);
                let key = day.substr(9,2)
              return <LinkTab label={date} href={'/' + date} {...a11yProps(2)} key={day} />
            })}
            </Tabs>
          {
          days.map(day => {
            i++;
            return (<TabPanel value={value} index={i} key={i} className={classes.tabRoot}>
              <Slide direction="right" in={rollIn} mountOnEnter unmountOnExit key={i}>
                <Calendar apiresponse={apiresponse} day={day} key={i} handleLogin={props.handleLogin} handleReservation={props.handleReservation} login={props.login} handleLoginForm={props.handleLoginForm}></Calendar>
              </Slide>
            </TabPanel>);
          })}
        </React.Fragment>)}

  return (
    <React.Fragment>
    {isLoaded ? content() : null}
    </React.Fragment>
  );
  
}

export default DaySelector;