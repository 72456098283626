import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CreatePDF from './pdf/CreatePDF';
import Status from './Status';

/*pdf creation handler*/
function renderPDF(apidata, setStatus, setCreatePDF)
{
  // setStatus({message:'PDF wird erstellt.', severity:'success'});
  return <CreatePDF apidata={apidata} statusHandler={setStatus} setCreatePDF={setCreatePDF}></CreatePDF>
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Kurs' },
  { id: 'start', numeric: true, disablePadding: false, label: 'Start' },
  { id: 'ende', numeric: true, disablePadding: false, label: 'Ende' },
  { id: 'Room.name', numeric: false, disablePadding: false, label: 'Raum' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all courses' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  
  const handlePDFCreate = (e) => {
    props.handlePDFCreate(true);
  }

  const handleDelete = () => {
    props.handleDelete();
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} ausgewählt
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Reservationen
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        
      ) : (
        <React.Fragment>
        <Tooltip title="ics exportieren">
        <IconButton aria-label="ics exportieren">
          <EventIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="PDF exportieren">
        <IconButton aria-label="PDF exportieren" onClick={handlePDFCreate}>
          <PictureAsPdfIcon />
        </IconButton>
      </Tooltip>
      </React.Fragment>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function Reservationen(props){
    const [apidata, setApidata] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [status, setStatus] = useState({
      severity: '',
      message: ''
  });

    function fetchData()
    {
        const options = {
            method: 'GET',
            // withCredentials: true,
            credentials: 'include'
        }
        const response = fetch('https://backend.yogameetsweggis.ch/login/reservations', options).then(res => res.json()).catch(err => console.log(err));
        return response; 
    }

    useEffect(() => {
      const apiResponse = fetchData().then(function(res) {
        setApidata([...res.Courses]);
      }
    ).then(setIsLoaded(prevState => !prevState))}, [])

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('start');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [createPDF, setCreatePDF] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = apidata.map((n) => n.id);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlePDFCreate = function(data) {
      setCreatePDF(data);
    }

    const handleDelete = async function() {
      let ids = []
      selected.map(courseId => {
        ids.push({courseId: String(courseId)})
      });

      console.log('courseIds: ' + JSON.stringify(ids))
      
      const login = await props.handleLogin().catch(res => console.log(res));
      console.log('passed auth: ' + login)
      if(login)
      {
        const options = 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(ids)
        }

        const result = await fetch("https://backend.yogameetsweggis.ch/seq/course/delete/reservation", options).then(res => res.json()).then(function(res) {
          if(res.result !== undefined)
          { 
            setStatus({severity: res.severity, message: res.result});
          }
        }).catch(err => console.log('le error: ' + err));

        setApidata(prevState => {
          let newState = [...prevState];
       
          ids.map(function(id) {
            
            newState = newState.filter(item => String(item.id) !== String(id.courseId));
          })
          return newState;
        });

        setSelected([]);
            
      }
      else
      {
          console.log('not logged in');
      }
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, apidata.length - page * rowsPerPage);

    const content = () => (<div className={classes.root}>
      <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} handlePDFCreate={handlePDFCreate} handleDelete={handleDelete}/>
          <TableContainer>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
          >
              <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={apidata.length}
              />
              <TableBody>
              {stableSort(apidata, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                  let start = new Date(row.start).toLocaleDateString('de-CH', options);
                  let end = new Date(row.end).toLocaleDateString('de-CH', options);

                  

                  return (
                      <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      >
                      <TableCell padding="checkbox">
                          <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                      </TableCell>
                      <TableCell align="right">{start}</TableCell>
                      <TableCell align="right">{end}</TableCell>
                      <TableCell align="right">{row.Room.name}</TableCell>
                      {/* <TableCell align="right">{row.instructor.name}</TableCell> */}
                      </TableRow>
                  );
                  })}
              {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                  </TableRow>
              )}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={apidata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </Paper>
      {createPDF ? renderPDF(apidata, setStatus, setCreatePDF) : null}
      <Status severity={status.severity} message={status.message} />
      </div>);

    return (
        <React.Fragment>
          {!isLoaded ? 'no content' : content()}
        </React.Fragment>
        
    );
}