import React, {useState, useEffect} from 'react';
import MainMenu from './components/MainMenu';
import LoginForm from './components/LoginForm'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import 'fontsource-roboto';
import { deDE } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#bbd5d2',
      light: '#c8dddb',
      dark: '#829593',
      text: '#000000',
    },
    secondary: {
      main: '#E8A425',
      light: '#ecb650',
      dark: '#a27219',
      text: '#000000',
    },
  }
}, deDE);


function App() {
  const [login, setLogin] = useState(
    {
      loggedIn: false,
      validTicket: [],
      reservations: [],
      message: ''
    }
  );

  useEffect(() => {
    if(login.loggedIn === false)
    {  
      handleLogin();
    }
  }, [])

  const handleLogin = (email, password, eguma) => new Promise((resolve, reject) =>
  {
    if(email === undefined && password === undefined && eguma === undefined)
    {
      
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // withCredentials: true,
        credentials: 'include'
      };
      
      const response = fetch('https://backend.yogameetsweggis.ch/login/auth', options).then(async function(data) {
        if(data.status === 200)
        {
          data = await data.json();
          console.log('will update login: ' + JSON.stringify({loggedIn: true, validTicket: data.Ticket.valid, reservations: data.Courses}))
          setLogin({loggedIn: true, validTicket: data.Ticket.valid, reservations: data.Courses});
          resolve(true);
        }
        else {
          resolve(false);  
        }
      }).catch((err) => reject(err))
  
    }
  
    else if(eguma !== '' && login.loggedIn === false && (email !== '' && password !== ''))
    {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // withCredentials: true,
        credentials: 'include',
        body: JSON.stringify({ email: email, password: password, eguma: eguma })
      };
      const response = fetch('https://backend.yogameetsweggis.ch/login/signup', options).then(res => res.json()).then(function (data) {
        if(data.message !== undefined)
        {
          setLogin({loggedIn: false, message: data.message});
          resolve(false);
        }
        else
        {
          setLogin({loggedIn: true, validTicket: data.Ticket.valid, reservations: data.Courses});
          handleLoginForm();
          resolve(true);
          
        }
        }).catch((err) => reject(err));
    }
    else
    {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // withCredentials: true,
        credentials: 'include',
        body: JSON.stringify({ email: email, password: password })
      };
      // const response = fetch('https://backend.yogameetsweggis.ch/login/login', options).then(response => response.json()).then(data => setLogin({...login.login, loggedIn: data.loggedIn }));
      const response = fetch('https://backend.yogameetsweggis.ch/login/login', options).then(res => res.json()).then(function(data) {
        // console.log(JSON.stringify(data))  
        if(data.message !== undefined)
        {
          setLogin({loggedIn: false, message: data.message});
          resolve(false);
        }
        else
        {
          setLogin({loggedIn: true, validTicket: data.Ticket.valid, reservations: data.Courses});
          handleLoginForm();
          resolve(true);
          
        }
      }).catch((err) => reject(err))
    }
  });

  const handleLogout = () =>
  {
    const options = { 
      // withCredentials: true, 
      credentials: 'include' }
    const response = fetch('https://backend.yogameetsweggis.ch/login/logout', options).then(response => response.json()).then(function (data) { 
      setLogin({loggedIn: data.loggedIn})

    });
  };

  const [openLogin, setOpenLogin] = useState(false);

  function handleLoginForm()
  {
      setOpenLogin(prevState => !prevState);
  }

  function handleReservation(courseId, action)
  {
    if(action === 'check')
    {
      console.log('inside check');
      const isReserved = login.reservations.some(function(reservation) {
        return courseId === reservation.id
      });

      if (isReserved === false) {
        return 'make';
      }
      else {
        return 'delete';
      }
    }
    else if(action === 'make')
    {
      setLogin(function(prevState)
      {
        let newState = {...prevState};
        newState.reservations = prevState.reservations.concat({id:courseId});
        return newState;  
      });
    }
    else if(action === 'delete')
    {
      setLogin(function(prevState)
      {
        let newState = {...prevState}
        newState.reservations = prevState.reservations.filter(item => item.id !== courseId);
        return newState;  
      });
    }
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MainMenu handleLoginForm={handleLoginForm} handleLogin={handleLogin} login={login} handleLogout={handleLogout} handleReservation={handleReservation}/>
        <LoginForm handleLogin={handleLogin} handleLoginForm={handleLoginForm} showLoginForm={openLogin} login={login}/>
      </ThemeProvider>
    </div>
  );
}

export default App;
